.container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.model-img {
  cursor: pointer;
  width: 100%;
  
  transition:opacity 0.5s linear;
}

.grayed-image {
  opacity: 0.5;
}

.item {
  width: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.label {
  margin-top: 10px; /* Adjust as needed */
  margin-bottom: 10px;
}

.main-table {
  display: flex;
  flex-wrap: wrap;
  flex-grow: 0;
  justify-content: center;
}

.title-column {
  /* flex: 1; */
  margin: 10px;
  padding: 15px;
  /* border: 1px solid #ccc; */
  /* border-radius: 8px; */
  /* background-color: #f4f4f4; */
  display: flex;
  flex-direction: column;
}

.column {
  flex: 0 1;
  margin: 10px;
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f4f4f4;
  display: flex;
  flex-direction: column;
  
}

.row {
  margin-bottom: 8px;
  white-space: nowrap; 
}

body {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  margin: 0;
}

.details-table {
  display: flex;
}


.details-column {
  flex: 1 1;
  margin-top: 10px;
  background-color: #f4f4f4;
  display: flex;
  flex-direction: column;
  
}