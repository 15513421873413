body, html {
  /* height: 100%;
  background-image: url("bg.jpg");
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover; */
}

.center-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  /* min-height: 100vh; */
  margin:20px;
}

.form-bg {
  opacity: 0.9;
  background-color: white;
  padding: 20px;
  border-radius: 4px;
}


.filters-box {
  font-weight: bold;
}

.filters {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.filter {
  margin: 10px;
}

.filterLabel {
  margin-bottom: 5px;
}

.description {
  font-size: 15px;
  color: #555;
  text-align: left;
  margin-top: 40px;
  max-width: 800px;
}

.title {
  font-size: 26px;
  color: #444;
  text-align: center;
}

a {
  cursor: pointer;
}

a:hover {
  color: #888;
}

.message {
  border: 1px solid #333;
  margin: 10px;
  padding: 10px;
  border-radius: 2px;
  color: #555;
  max-width: 800px;
}